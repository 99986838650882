.question-wrapper {
  position: relative;
  bottom: 1px;
  left: 3px;
  color: #586267;
  :hover,
  :focus {
    opacity: 0.7;
  } 
}

